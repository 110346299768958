// Here you can add other styles

body{
    font-size: 1rem;
    font-family: 'Noto Sans TC', sans-serif;

}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 8px;
}
 
/* Track */
::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    // -webkit-border-radius: 10px;
    // border-radius: 10px;
    background: rgba(170, 170, 170, 0.8); 
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0, 0, 0, 0.4); 
}

.orio-btn{
    color: #ffffff;
    background-color: #2c2e82;
    border-color: #2c2e82;
    &:hover{
        background-color: #1C1F94;
        border-color: #1C1F94
    }
}

.orio-btn-light{
    color: #1C1F94;
    background-color: #ffffff;
    border-color: #1C1F94;
    &:hover{
        background-color: #ffffff;
        border-color: #1C1F94
    }
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #1b1c4f;
    border-color: #1b1c4f;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #1b1c4f; 
}

.btn-primary:focus, .btn-primary.focus { 
    box-shadow: 0 0 0 0.2rem #1b1c4f; 
}
.orio-btn-cancel{
    color: #000000;
    background-color: #ffffff;
    border-color: #1b1c4f;
}

.rectangle {
    height: 30px;
    opacity: 0.5;
    border-radius: 10px;
    background-color: #ffffff;
    }


.img{
    .marker-focus{
        height: 50px;
    }
}
.start-logo{
    width: 167px;
    height: 47px;
    opacity: 0.5;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    position: relative;
    &::after,&::before{
        content: "";
        width: 180px;
        height: 3px;
        opacity: 0.5;
        background-color: #1C1F94;
        position: absolute;
        top: 22px;
        right:220px
        
    }
    &::after{
        left:220px
    }

}

.login-button{
    font-size: 16px;
    height: 40px;
    width: 216px;
}

.start-rectangle {
    width: 180px;
    height: 3px;
    opacity: 0.5;
    background-color: #1C1F94;
}

.relocate-marker{
    margin: -11px;
}

.orio-background{
    background-color: #ffffff;
}

.orio-background-gray{
    background-color: #f2f2f9;
    position: relative;
}

.gray-card{
    background-color: #f5f5f5;
}

.f-weight600{
    font-weight: 600;
}

.f-weight800{
    font-weight: 800;
}

.map-control{
    padding: 6px;
    margin-top: 20px;
    margin-right: 20px;
    width: 157px;
    height: 140px;
    opacity: 0.7;
    border-radius: 10px;
    border: solid 1px rgba(28, 31, 148, 0.1);
    background-color: #ffffff;
}


.map-control-device-list-rwd-type{
    padding: 6px;
    margin-top: 30px;
    margin-right: 20px;
    width: 97vw;
    height: 90vh;
    opacity: 0.9;
    border-radius: 10px;
    border: solid 1px rgba(28, 31, 148, 0.1);
    background-color: #1b1c4f;
    font-size: 4vw;
}
.rwd-card-theme{
    margin-top: 5vw;
    height: 10vh;
}
.etagMap-control{
    padding: 6px;
    margin-top: 20px;
    margin-right: 20px;
    width: 157px;
    height: 240px;
    opacity: 0.7;
    border-radius: 10px;
    border: solid 1px rgba(28, 31, 148, 0.1);
    background-color: #ffffff;
}

.pill{
    border-radius: 50em;
}

.orio-title-text{
    color: #1c1f94;
    font-weight: 800;
}

.orio-device-text{
    color: #9b9b9b;
}

.orio-title-text-danger{
    color: #d0021b;
}

.orio-link-normal{
    color: #1C1f94;
}

.orio-link-back{
    color: #ffffff;
}

.nav{
    .orio-link-normal{
        width: 120px;
        
    }
    .nav-item{
        position: relative;
        min-width: 120px;
    }
    .active{
        &::after{
            content: "";
            height: 3px;
            width: 120px;
            background:#f5a623;
            position: absolute;
            top: 35px;
            left: 2px;
        }
    }
}

.orio-link-danger{
    color: #d0021b;
}

.orio-sidebar{
    color: #ffffff;
    background: #1b1c4f;
    
}

.sidebar .nav-link{
    &:hover{
        background-color: #2C2E82;
        border-color: #2C2E82
    }
}

.orio-col{
    
    &:hover{
        .hideden-button{
            display: inline;
        }
    }
}
.name-col{
    height:30px;
}
.hover-gray{
    &:hover{
        background-color: #d4d4d4;
    }
}
.disable{
    opacity: 0.5;
}
.status-error{
    background-color: rgba(208,2,27,0.1);
    
}

.orio-saas-map{
    height: 95vh;
    width: 100%;
    // background-color: gray
}

.leaflet-container {
    height: 95vh;
    width: 100%;
    
  }

.mapAddDevice {
    &:hover{
        cursor: url("https://i.imgur.com/Bl1J7qV.jpg"), auto;
    }
}

.orio-nav{
    justify-content: flex-start;
}

.orio-nav-dropdown{
    margin: 0 0 0 auto ;
}

.restore-col-style{
    position: absolute;
    top: 50%;
    transform: translate(-15px,-50%);
    z-index: 1;
}

.restore-btn-style{
    width: 24px;
    height: 112px;
    border-radius: 0 10px 10px 0
}

.restore-btn-styl-left{
    width: 24px;
    height: 112px;
    border-radius: 10px 0 0 10px
}

.restore-btn-space-left{
    width: 24px;
}

.restore-col-style-left{
    position: absolute;
    top: 50%;
    transform: translate(-0px,-60%);
    z-index: 1;
}

.card{
    border:none;
}

.margin-top-20 {
    margin-top: 20vh;
}

.margin-top-30 {
    margin-top: 30vh;
}

.margin-left-10 {
    margin-left: 10px;
}

hr{
    margin: 0px
}
.form-group{
    padding: 1rem;
    margin: 0px;
}

.info{
    width: 157px;
    height: 140px;
    opacity: 0.7;
    border-radius: 10px;
    border: solid 1px rgba(28, 31, 148, 0.1);
    background-color: #ffffff;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: normal;
    color: #4a4a4a;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #c8ced3;
}

.leaflet-control-attribution {
    display: none
}


.anomaly-component{
    margin-top: 3vh;
    margin-left: 6vh;
    margin-right: 3vh;
}

.hetght500{
    height: 500px;
}

.top30{
    margin-top: 30vh;
}

.device-search{
    background-color: #0e0e2c;
    border: #0e0e2c;
    color: #f2f2f9;
    height: 48px;
    // width: 22vh;
    &:focus{
        color: #f2f2f9;
        background-color: #0e0e2c;
        border-color: #0e0e2c;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    }
}

.device-search-btn{
    background-color: #0e0e2c;
    border: #0e0e2c;
    color: #f2f2f9;
    &:focus{
        color: #f2f2f9;
        background-color: #0e0e2c;
        border-color: #0e0e2c;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    }
    &:active{
        color: #f2f2f9;
        background-color: #0e0e2c;
        border-color: #0e0e2c;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    }
    &:hover{
        color: #f2f2f9;
        background-color: #0e0e2c;
        border-color: #0e0e2c;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
    }
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #f2f2f9;
    background-color: #0e0e2c;
    border-color: #0e0e2c;
}
.device-search-form{
    background-color: #0e0e2c;
    // width: 325px;
}

.sidebar{
    padding:-0.7rem 0rem;
}

.sidebar .nav{
    width: 320px;
}
.sidebar .sidebar-nav {
    width: 320px;
}

.button-add-thing{
    margin-left: 230px;
}

.hideden-button{
    display: none;
    // &:hover{
    //     display: inline
    // }
}

.etag-sidebar{
    margin-left: 15px;
}

.etag-checkbox{
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #ffffff;
}

.etag-weather-card{
    background-color: #f2f2f9;
}

.Rectangle {
    width: 3px;
    height: 82px;
    background-color: #d8d8d8;
    margin-left: 15px;
}

.overflow-scrolling {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 85vh;
}

.overflow-scrolling-modbus-config {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 50vh;
}

.overflow-scrolling-thingInfo {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 90vh;
}

.input-file-style{
    display:none;
}

.thing-model-other-option{
    background:#f5a623;
}