.addThing-overflow-scrolling {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 260px;
}

.height40vh {
    height: 40vh;
}

.overflow-scrolling-sidebar {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    /* margin-right: 12px; */
    height: 78vh;
    /* width: 300px; */
}